import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import Loader from "../../custom/loader";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { firestore } from "firebase";
import uuid from "react-uuid";
import { UPDATE_SECTION_TYPE } from "../../../store/actions";
import { updateType } from "../../../store/actions/typeActions";
import Sync from "./sync";

const filesPath = "base-images-new";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const Previews = (props) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [type, setType] = useState(1);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const upload = async (e) => {
    e.preventDefault();
    setLoading(true);
    props.firebase
      .uploadFiles(filesPath, files, filesPath, {
        metadataFactory: (_uploadRes, _firebase, metadata, downloadURL) => {
          const id = uuid();
          firestore().collection("images").doc(id).set({
            createdAt: firestore.Timestamp.now(),
            type: type,
            src: downloadURL,
          });
          if (metadata?.name === files[files.length - 1]?.path) {
            document.getElementById("file-form").reset();
            document.getElementById("file-input").value = "";
            setFiles([]);
            setLoading(false);
          }
          return { fileUrl: downloadURL };
        },
        documentId: "12345",
      })
      .then(() => {
        console.log("File uploaded successfully");
      });
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);
  return (
    <form id="file-form">
      <div style={{ justifyContent: "flex-end", display: "flex" }}>
        <Sync />
        <FormControl style={{ width: 200, marginRight: 20 }}>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={type}
            label="Age"
            onChange={(e) => {
              setType(e.target.value);
              props.dispatch(
                updateType({
                  type: UPDATE_SECTION_TYPE,
                  val: e.target.value,
                })
              );
            }}
          >
            {props?.types?.map((ty) => {
              return (
                <MenuItem key={ty.id} value={ty.id}>
                  {ty.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        <Button
          onClick={upload}
          disabled={!files?.length}
          style={{ margin: "20px 0" }}
          variant="contained"
          color="primary"
          // type="submit"
        >
          Upload
        </Button>
      </div>
      <div className="preview">
        <section className="dropzone-container">
          <div {...getRootProps({ className: "dropzone" })}>
            <input id="file-input" {...getInputProps()} accept="image/*" />
            <p>Drag 'n' drop some files here, or click to select files</p>
          </div>
          <aside style={thumbsContainer}>{thumbs}</aside>
        </section>
      </div>
      {loading ? (
        <div className="loader-upper">
          <Loader />
        </div>
      ) : null}
    </form>
  );
};

const mapStateToProps = (state) => {
  return {
    types: state?.firestore?.data?.data?.Image?.imageSections || [],
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => ["data"])
)(Previews);
