import * as actions from "../actions";

const initState = {
  sectionType: 1,
};

const typeReducer = (state = initState, action) => {
  switch (action.type) {
    case actions.UPDATE_SECTION_TYPE:
      return {
        ...initState,
        sectionType: action?.val,
      };

    default:
      return state;
  }
};

export default typeReducer;
