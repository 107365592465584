import React from "react";
import "./styles.css";
import MenuIcon from "@material-ui/icons/FormatListBulleted";
import { firestore } from "firebase";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

function SortableList({ imageSections }) {
  console.log("SortableList imageSections => ", imageSections);
  // const [imageSections, setFruitItems] = React.useState([]);
  const [newFruitItem, setNewFruitItem] = React.useState("");

  //save reference for dragItem and dragOverItem
  const dragItem = React.useRef(null);
  const dragOverItem = React.useRef(null);

  //const handle drag sorting
  const handleSort = () => {
    //duplicate items
    let newSections = [...imageSections];

    //remove and save the dragged item content
    const draggedItemContent = newSections.splice(dragItem.current, 1)[0];

    //switch the position
    newSections.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the position ref
    dragItem.current = null;
    dragOverItem.current = null;

    console.log("handleSort newSections => ", newSections);
    //update the actual array
    // setFruitItems(newSections);
    firestore()
      .collection("data")
      .doc("Image")
      .set({ imageSections: newSections });
  };

  //handle name change
  const handleNameChange = (e) => {
    setNewFruitItem(e.target.value);
  };

  //handle new item addition
  const handleAddItem = () => {
    const newSections = [...imageSections];
    newSections.push({ name: newFruitItem, id: newSections?.length + 1 });
    console.log("handleAddItem newSections => ", newSections);

    firestore()
      .collection("data")
      .doc("Image")
      .set({ imageSections: newSections })
      .then((snapshot) => {
        document.getElementById("input").value = "";
        // this.setState({ loading: false, user: snapshot?.data() });
      });
    // .catch((e) => {
    //   this.setState({ loading: false, user: null });
    // });

    // setFruitItems(newSections);
  };

  return (
    <div className="app">
      <h2>Image Sections</h2>
      <div className="input-group">
        <input
          type="text"
          name="fruitName"
          placeholder="e.g First"
          onChange={handleNameChange}
          id="input"
        />
        <button className="btn" onClick={handleAddItem}>
          Add Section
        </button>
      </div>

      {/** List container //TODO break into component */}
      <div className="list-container">
        {imageSections.map((item, index) => (
          <div
            key={index}
            className="list-item"
            draggable
            onDragStart={(e) => (dragItem.current = index)}
            onDragEnter={(e) => (dragOverItem.current = index)}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()}
          >
            <MenuIcon style={{ marginRight: 10 }} />
            <h3>{item?.name}</h3>
          </div>
        ))}
      </div>
    </div>
  );
}

// export default App;

const mapStateToProps = (state) => {
  console.log("state => ", state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    imageSections: state?.firestore?.data?.data?.Image?.imageSections || [],
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => ["data"])
)(SortableList);
