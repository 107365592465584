import Typography from "@material-ui/core/Typography";
import { firestore } from "firebase";
import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import Loader from "../../custom/loader";
import { SortableGallery } from "./Items";

import Dropzone from "./Dropzone";

class Images extends Component {
  state = {
    type: 1,
  };
  onSortEnd = async ({ oldIndex, newIndex }) => {
    const { images } = this.props;

    const { createdAt: createdAtOld, id: idOld } = images[oldIndex];
    const { createdAt: createdAtNew, id: idNew } = images[newIndex];

    if (createdAtOld?.seconds === createdAtNew?.seconds) {
      await firestore()
        .collection("images")
        .doc(idNew)
        .update({
          createdAt: { ...createdAtOld, seconds: createdAtOld?.seconds + 1 },
        });
    } else {
      await firestore()
        .collection("images")
        .doc(idNew)
        .update({ createdAt: createdAtOld });
      firestore()
        .collection("images")
        .doc(idOld)
        .update({ createdAt: createdAtNew });
    }
  };

  render() {
    const { images = [], auth, loading } = this.props;

    if (!auth.uid) return <Redirect to="/signin" />;

    if (!isLoaded()) {
      return <Loader />;
    }

    // if (!images?.length) {
    //   return null;
    // }

    return (
      <div style={{ width: "90%", margin: "auto", marginTop: 20 }}>
        <Typography style={{ marginLeft: 10, marginBottom: 30 }} variant="h4">
          Images
        </Typography>
        <Dropzone />

        <div style={{ clear: "both", height: 1, width: "100%" }}></div>
        {images ? (
          images.length ? (
            <div>
              {/* <h2>Sortable Gallery</h2> */}
              {/* <h3>Drag photo to rearrange</h3> */}
              <SortableGallery
                firebase={this.props.firebase}
                items={[...images]}
                onSortEnd={this.onSortEnd}
                axis={"xy"}
              />
            </div>
          ) : loading ? (
            <></>
          ) : (
            <Typography variant="h5">There are no user(s) to show</Typography>
          )
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    images: state.firestore?.ordered?.images?.map((i) => ({
      ...i,
      width: 1,
      height: 1,
    })),
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props, a) => {
    return [
      {
        collection: "images",
        where: ["type", "==", a.getState()?.typeReducer?.sectionType],
        orderBy: ["createdAt", "desc"],
      },
    ];
  })
)(Images);
