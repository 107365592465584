import * as actions from '../actions';

const initState = {
    authError: null,
    loading: false,
    userDetails: null
}

const authReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.LOGIN_START:
            return {
                ...initState,
                loading: true
            }
        case actions.LOGIN_SUCCESS:
            return {
                ...initState,
                authError: null,
                userDetails: action.payload
            }
        case actions.LOGIN_ERROR:
            return {
                ...initState,
                authError: action.payload,
                userDetails: null
            }

        default:
            return state
    }
}

export default authReducer