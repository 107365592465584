import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

//put your own config from firebase project
var config = {
  apiKey: "AIzaSyDlEhtKP-X96rirbOrta4i582bfaU9_Doc",
  authDomain: "image-creater.firebaseapp.com",
  projectId: "image-creater",
  storageBucket: "image-creater.appspot.com",
  messagingSenderId: "964879947461",
  appId: "1:964879947461:web:5dae550c75247b399ac2ac",
  measurementId: "G-N2K0WRDF35"
};

firebase.initializeApp(config);

export default firebase;
