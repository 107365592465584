import React, { Component } from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import PrivacyPolicy from "./components/auth/PrivacyPolicy";
import SignIn from "./components/auth/SignIn";
import TermsAndConditions from "./components/auth/TermsAndConditions";
import Navbar from "./components/layout/Navbar";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import Users from "./components/screens/Users";
import Feedbacks from "./components/screens/Feedbacks";
import Images from "./components/screens/Images";
import Sections from "./components/screens/Sections";
import Bugs from "./components/screens/Bugs";
import Other from "./components/screens/Other";

class App extends Component {
  render() {
    if (!isLoaded()) return null;

    const { profile } = this.props;

    if (profile && profile.email) {
      if (
        (profile.role === 2 && profile.disableByRoot) ||
        (profile.role === 3 && profile.disableBySuperAdmin) ||
        (profile.role === 4 && profile.disableByAdmin)
      ) {
        return (
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              fontSize: 20,
              display: "flex",
              height: "100vh",
              width: "100%",
              color: "#f00",
            }}
          >
            You are not allowed to do any action please contact to the Admin for
            more details
          </div>
        );
      }
    }

    return (
      <BrowserRouter>
        <div>
          <Navbar />
          <Switch>
            <Route path="/users" component={Users} />
            <Route path="/images" component={Images} />
            <Route path="/sections" component={Sections} />
            <Route path="/feedbacks" component={Feedbacks} />
            <Route path="/bugs" component={Bugs} />
            <Route path="/other" component={Other} />
            <Route path="/signin" component={SignIn} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditions}
            />
            <Route path="/" component={Users} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

// export default App;

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(App);
