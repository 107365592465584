import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { signedOutList } from "../../data/tiledata";
import SignedInLinks from "./SignedInLinks";
import SignedOutLinks from "./SignedOutLinks";

// import Fab from '@material-ui/core/Fab';
// import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
// import Chats from "./Chats";

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
};

class ButtonAppBar extends React.Component {
  state = {
    left: false,
  };

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { classes, auth, profile, navigations } = this.props;
    // console.log("state.firebase.navigations => ", navigations);

    if (!auth.uid) return null;
    if (!profile && !profile.isLoaded) return null;

    const links = auth.uid ? (
      <SignedInLinks profile={profile} navigations={navigations} />
    ) : (
      <SignedOutLinks />
    );
    const sidelinks = auth.uid ? (
      <></>
    ) : (
      signedOutList
    );

    const sideList = <div className={classes.list}>{sidelinks}</div>;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <Hidden only={["xl", "lg", "md"]}>
              <IconButton
                onClick={this.toggleDrawer("left", true)}
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
            <Typography variant="h5" color="inherit" className={classes.grow}>
              {profile && profile.organization && profile.organization.title}
            </Typography>
            {links}
          </Toolbar>
        </AppBar>
        <Drawer
          open={this.state.left}
          onClose={this.toggleDrawer("left", false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer("left", false)}
            onKeyDown={this.toggleDrawer("left", false)}
          >
            {sideList}
          </div>
        </Drawer>
        {/* <Chats /> */}
      </div>
    );
  }
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    navigations: state.firestore.ordered.navigations,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    if (props.profile.role === 1) {
      return [
        {
          collection: "navigations",
          where: ["RootUser", "==", true],
        },
      ];
    } else if (props.profile.role === 2) {
      return [
        {
          collection: "navigations",
          where: ["SuperAdmin", "==", true],
        },
      ];
    } else if (props.profile.role === 3) {
      return [
        {
          collection: "navigations",
          where: ["Admin", "==", true],
        },
      ];
    } else if (props.profile.role === 4) {
      return [
        {
          collection: "navigations",
          where: ["User", "==", true],
        },
      ];
    } else {
      return [];
    }
  })
)(withStyles(styles)(ButtonAppBar));
