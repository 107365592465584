import React from 'react'
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress'

const Loader = () => {
  return <Typography variant="h4" component="h3" gutterBottom align="center">
  <CircularProgress align="center" />
</Typography>
}

export default Loader;
