import * as actions from "../actions";

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: actions.LOGIN_START,
    });

    const firebase = getFirebase();
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((data) => {
        console.log("data => ",data)
        dispatch({
          type: actions.LOGIN_SUCCESS,
        });
      })
      .catch((err) => {
        console.log("err => ",err)

        dispatch({
          type: actions.LOGIN_ERROR,
          payload: err,
        });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        dispatch({
          type: "SIGNOUT_SUCCES",
        });
      });
  };
};
