import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import Loader from "../../custom/loader";
import SortableList from "./sortable-list";

class Feedbacks extends Component {
  render() {
    const { feedbacks = [], auth, loading } = this.props;

    if (!isLoaded()) {
      return <Loader />;
    }

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div style={{ width: "90%", margin: "auto", marginTop: 20 }}>
        <SortableList />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("state => ", state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    // feedbacks: state.firestore.ordered.feedback,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => [])
)(Feedbacks);
