import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";

import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import { ExportToCsv } from "export-to-csv";
import { firestore } from "firebase";
import moment from "moment";

export default function UserTable() {
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [totalCount, setCount] = useState(0);
  const [pageCount, setPageCount] = useState(10);

  const loading = false;
  const fetchData = async () => {
    await firestore()
      .collection("Users")
      .orderBy("createdAt", "desc")
      .limit(pageCount)
      .onSnapshot(function (querySnapshot) {
        var items = [];
        querySnapshot.forEach(function (doc) {
          items.push({ key: doc.id, ...doc.data() });
        });
        setList(items);
      });
  };

  useEffect(() => {
    fetchData();
  }, [pageCount]);

  useEffect(() => {
    firestore()
      .collection("Users")
      .get()
      .then((snapshot) => setCount(snapshot.docs.length))
      .catch((e) => {
        console.log("error = >", e);
      });
  }, []);

  const showNext = ({ item }) => {
    if (list.length === 0) {
      alert("Thats all we have for now !");
    } else {
      const fetchNextData = async () => {
        await firestore()
          .collection("Users")
          .orderBy("createdAt", "desc")
          .limit(pageCount)
          .startAfter(item?.createdAt)
          .onSnapshot(function (querySnapshot) {
            const items = [];
            querySnapshot.forEach(function (doc) {
              items.push({ key: doc.id, ...doc.data() });
            });
            setList(items);
            setPage(page + 1);
          });
      };
      fetchNextData();
    }
  };

  const showPrevious = ({ item }) => {
    const fetchPreviousData = async () => {
      await firestore()
        .collection("Users")
        .orderBy("createdAt", "desc")
        .endBefore(item?.createdAt)
        .limitToLast(pageCount)
        .onSnapshot(function (querySnapshot) {
          const items = [];
          querySnapshot.forEach(function (doc) {
            items.push({ key: doc.id, ...doc.data() });
          });
          setList(items);
          setPage(page - 1);
        });
    };
    fetchPreviousData();
  };

  const onDownload = () => {
    const columns = [
      // {
      //   accessorKey: "key",
      //   header: "ID",
      //   size: 40,
      // },
      {
        accessorKey: "key",
        header: "Phone",
        size: 300,
      },
      {
        accessorKey: "img",
        header: "image",
        size: 40,
      },
      {
        accessorKey: "fullName",
        header: "Name",
        size: 120,
      },
    ];

    const csvOptions = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      useBom: true,
      useKeysAsHeaders: false,
      headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    csvExporter.generateCsv(list);
  };

  return (
    <div style={{ width: "90%", margin: "auto", marginTop: 20 }}>
      <Typography style={{ marginLeft: 10 }} variant="h4">
        Users - ({totalCount})
      </Typography>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          variant="contained"
          color={pageCount === 100 ? "primary" : "dark"}
          style={{ marginRight: 10 }}
          onClick={() => setPageCount(100)}
        >
          100
        </Button>
        <Button
          variant="contained"
          color={pageCount === 50 ? "primary" : "dark"}
          style={{ marginRight: 10 }}
          onClick={() => setPageCount(50)}
        >
          50
        </Button>
        <Button
          variant="contained"
          color={pageCount === 10 ? "primary" : "dark"}
          style={{ marginRight: 10 }}
          onClick={() => setPageCount(10)}
        >
          10
        </Button>
        {/* <Button
          variant="contained"
          color={pageCount === 1000 ? "primary" : "dark"}
          style={{ marginRight: 10 }}
          onClick={() => setPageCount(1000)}
        >
          1000
        </Button> */}
        <DownloadIcon
          onClick={onDownload}
          style={{ marginRight: 10, cursor: "pointer" }}
        />
      </div>
      <>
        {list ? (
          list.length ? (
            <>
              <TableContainer
                style={{ marginTop: 20, marginBottom: 20 }}
                component={Paper}
                options={{
                  exportButton: true,
                  exportAllData: true,
                }}
              >
                <Table
                  options={{
                    exportButton: true,
                    exportAllData: true,
                  }}
                  size="small"
                  aria-label="a dense table"
                >
                  <TableHead>
                    <TableRow>
                      {/* <TableCell>No.</TableCell> */}
                      <TableCell>Image</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Mobile No</TableCell>
                      <TableCell>Creation Time</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.map((user, index) => {
                      const {
                        key: phone,
                        fullName,
                        img,
                        createdAt,
                      } = user || {};
                      return (
                        <TableRow key={`userid=${phone}`}>
                          {/* <TableCell>{list?.length - index}.</TableCell> */}
                          <TableCell>
                            {img && !img?.includes("file:///") && (
                              <img
                                style={{
                                  width: 50,
                                  height: 50,
                                  borderRadius: 25,
                                }}
                                src={img}
                              />
                            )}
                          </TableCell>
                          <TableCell>{fullName}</TableCell>
                          <TableCell>{phone}</TableCell>
                          <TableCell>
                            {moment(
                              new Date(
                                createdAt?.toDate()?.toLocaleString("en-US")
                              )
                            ).format("ddd DD MMM hh:mm")}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : loading ? (
            <></>
          ) : (
            <Typography variant="h5">There are no user(s) to show</Typography>
          )
        ) : null}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {
            //show previous button only when we have items
            page === 1 ? (
              <a />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => showPrevious({ item: list[0] })}
              >
                Previous
              </Button>
            )
          }

          {
            //show next button only when we have items
            list.length < 5 ? (
              <a />
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={() => showNext({ item: list[list.length - 1] })}
              >
                Next
              </Button>
            )
          }
        </div>
      </>
    </div>
  );
}
