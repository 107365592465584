import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import Loader from "../../custom/loader";

import { firestore } from "firebase";
import "./styles.css";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

function Other({ shareText, auth, shareLink, downloadAsShare }) {
  const [loading, setLoading] = useState(false);
  const [shareTextVal, setShareTextVal] = useState(shareText);
  const [shareLinkVal, setShareLinkVal] = useState(shareLink);
  const [downloadAsShareVal, setDownloadAsShareVal] = useState(downloadAsShare);

  useEffect(() => {
    setShareTextVal(shareText);
  }, [shareText]);

  useEffect(() => {
    setShareLinkVal(shareLink);
  }, [shareLink]);

  useEffect(() => {
    setDownloadAsShareVal(downloadAsShare);
  }, [downloadAsShare]);

  const handleShareTextChange = (e) => {
    setShareTextVal(e.target.value);
  };

  const handleShareLinkChange = (e) => {
    setShareLinkVal(e.target.value);
  };

  const handleAddItem = () => {
    setLoading(true);
    firestore()
      .collection("data")
      .doc("Other")
      .set({
        shareText: shareTextVal,
        shareLink: shareLinkVal,
        downloadAsShare: downloadAsShareVal,
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (!isLoaded()) {
    return <Loader />;
  }

  if (!auth.uid) return <Redirect to="/signin" />;

  return (
    <>
      <div style={{ width: "90%", margin: "auto", marginTop: 20 }}>
        <h2>Image Sections</h2>
        <div className="input-group">
          <textarea
            value={shareTextVal}
            placeholder="Add Share Text"
            onChange={handleShareTextChange}
            style={{ padding: 10 }}
          ></textarea>
          <input
            type="text"
            value={shareLinkVal}
            placeholder="Add Share link"
            onChange={handleShareLinkChange}
            style={{ marginTop: 20, marginBottom: 20 }}
          />

          <ToggleButtonGroup
            color="primary"
            value={downloadAsShareVal}
            exclusive
            onChange={(event, val) => {
              setDownloadAsShareVal(val);
            }}
            aria-label="Platform"
          >
            <ToggleButton value={true}>Yes</ToggleButton>
            <ToggleButton value={false}>No</ToggleButton>
          </ToggleButtonGroup>

          <button
            className="btn"
            onClick={handleAddItem}
            style={{ maxWidth: 300 }}
          >
            Update
          </button>
        </div>
      </div>
      {loading ? (
        <div className="loader-upper">
          <Loader />
        </div>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    shareText: state?.firestore?.data?.data?.Other?.shareText || "",
    downloadAsShare:
      state?.firestore?.data?.data?.Other?.downloadAsShare || false,
    shareLink: state?.firestore?.data?.data?.Other?.shareLink || "",
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => ["data"])
)(Other);
