import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import {
  CheckBoxOutlineBlankRounded,
  CheckBoxRounded,
} from "@material-ui/icons";
import { firestore } from "firebase";
import React, { useState } from "react";
import Gallery from "react-photo-gallery";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import Loader from "../../custom/loader";
import Photo from "./Photo";
// import { getStorage, ref, deleteObject } from "firebase/storage";

/* popout the browser and maximize to see more rows! -> */
const SortablePhoto = SortableElement((item) => (
  <Photo
    key={item?.id}
    {...item}
    onClick={(data) => {
      console.log("data => ", data, item);
    }}
  />
));
export const SortableGallery = SortableContainer(({ items, firebase }) => {
  const [open, setOpen] = useState("");
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClickOpen = (src) => {
    setOpen(src);
  };

  const handleClose = () => {
    setOpen("");
  };

  const setDefaultImage = async () => {
    await firestore()
      .collection("data")
      .doc("Sync")
      .update({ defaultImage: open });

    handleClose();
  };

  const deleteSelectedImages = async () => {
    setLoading(true);

    for (let index = 0; index < selected.length; index++) {
      const img = selected[index];
      const src = decodeURIComponent(img?.src); //?.split('/o/')?.[1]?.split('?alt=')?.[0]
      const path = src?.split("/o/")?.[1]?.split("?alt=")?.[0];

      try {
        const storageRef = firebase.storage().ref(path);
        await storageRef.delete();
        await firestore().collection("images").doc(img?.id).delete();
      } catch (e) {
        console.log("e => ", e);
      }
    }
    setSelected([]);
    setLoading(false);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          onClick={deleteSelectedImages}
          disabled={!selected?.length || loading}
          style={{ margin: "20px 0" }}
          variant="contained"
          color="secondary"
        >
          Delete Images
        </Button>
      </div>

      <Gallery
        onClick={(i) => console.log("click=>", i)}
        photos={items || []}
        renderImage={(props) => {
          const isSelected = selected.findIndex(
            (i) => i?.id === props?.photo?.id
          );
          return (
            <div key={props?.photo?.id} style={{ position: "relative" }}>
              <img
                key={"image-" + props?.photo?.id}
                // style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
                {...props?.photo}
                onClick={() => handleClickOpen(props?.photo?.src)}
                alt="img"
              />
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                  backgroundColor: "#fff",
                  padding: 5,
                }}
              >
                {isSelected >= 0 ? (
                  <CheckBoxRounded
                    color="error"
                    onClick={() => {
                      selected.splice(isSelected, 1);
                      setSelected([...selected]);
                    }}
                  />
                ) : (
                  <CheckBoxOutlineBlankRounded
                    color="error"
                    onClick={() => {
                      selected.push(props?.photo);
                      setSelected([...selected]);
                    }}
                  />
                )}
              </div>
            </div>
            // <SortablePhoto key={"SortablePhoto-" + props?.id} {...props} />
          );
        }}
      />
      <Dialog
        open={!!open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure, you want to set this as a default image?
          </DialogContentText>
          <div>
            <img src={open} alt="img" />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={setDefaultImage} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      {loading ? (
        <div className="loader-upper">
          <Loader />
        </div>
      ) : null}
    </>
  );
});
