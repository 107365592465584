export const GET_LIST_START = "GET_LIST_START";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const GET_LIST_ERROR = "GET_LIST_ERROR";

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_RESET = "LOGIN_RESET";

export const UPDATE_SECTION_TYPE = "UPDATE_SECTION_TYPE";
