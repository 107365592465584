import React from "react";
import { CheckBox } from "@material-ui/icons";


const imgWithClick = { cursor: "pointer" };

const Photo = ({ index, onClick, photo, margin, direction, top, left }) => {
  const imgStyle = { margin: margin };
  if (direction === "column") {
    imgStyle.position = "absolute";
    imgStyle.left = left;
    imgStyle.top = top;
  }

  const handleClick = (event) => {
    console.log("handleClick data => ", event);
    onClick(event, { photo, index });
  };

  return (
    <div>
      <img
        style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
        {...photo}
        onClick={onClick ? handleClick : null}
        alt="img"
      />
      <CheckBox style={{ marginRight: 10 }} />
    </div>
  );
};

export default Photo;
