import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { compose } from "redux";
import { signOut } from "../../store/actions/authActions";
import Hidden from "@material-ui/core/Hidden";

const styles = {
  avatar: {
    margin: 10,
  },
  row: {
    display: "flex",
    justifyContent: "center",
  },
  link: {
    textDecoration: "none",
    color: "white",
  },
};

const SignedInLinks = (props) => {
  const { classes, navigations } = props;
  return (
    <div>
      <Hidden only={["xs", "sm"]}>
        <NavLink to="/" style={styles.link}>
          <Button color="inherit">Users</Button>
        </NavLink>
        <NavLink to="/sections" style={styles.link}>
          <Button color="inherit">Sections</Button>
        </NavLink>
        <NavLink to="/images" style={styles.link}>
          <Button color="inherit">Images</Button>
        </NavLink>
        <NavLink to="/feedbacks" style={styles.link}>
          <Button color="inherit">Feedback</Button>
        </NavLink>
        <NavLink to="/bugs" style={styles.link}>
          <Button color="inherit">Bug</Button>
        </NavLink>
        <NavLink to="/other" style={styles.link}>
          <Button color="inherit">Other</Button>
        </NavLink>
        {navigations && navigations.length
          ? navigations.map((navigation) => {
              return (
                <NavLink
                  to={navigation.path}
                  key={navigation.path}
                  style={styles.link}
                >
                  <Button color="inherit">{navigation.title}</Button>
                </NavLink>
              );
            })
          : null}
      </Hidden>
      <NavLink to="/" style={styles.link}>
        <Button color="inherit" onClick={props.signOut}>
          Log Out{" "}
        </Button>
      </NavLink>
      {/* <Notifications /> */}
      {/* <Button color="inherit"><Avatar className={classes.avatar}><NavLink to='/' style={styles.link}>{props.profile.initials}</NavLink> </Avatar> </Button> */}
    </div>
  );
};

const mapDispatchToProps = (disptach) => {
  return {
    signOut: () => disptach(signOut()),
  };
};

export default compose(
  withStyles(styles),
  connect(null, mapDispatchToProps)
)(SignedInLinks);
