import * as actions from "../actions";

export const updateType = (action) => {
  console.log("updateType val => ", action?.val);
  return (dispatch, getState, { getFirebase }) => {
    dispatch({
      type: actions.UPDATE_SECTION_TYPE,
      val: action?.val,
    });
  };
};
