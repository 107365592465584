import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { firestore } from "firebase";
import moment from "moment";
import React, { useEffect } from "react";

export const Sync = () => {
  const [open, setOpen] = React.useState(false);
  const [syncValue, setSyncValue] = React.useState(false);

  useEffect(() => {
    firestore()
      .collection("data")
      .doc("Sync")
      .onSnapshot((data) => {
        setSyncValue(parseInt(data?.data()?.sync));
      });
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const updateSync = async () => {
    await firestore()
      .collection("data")
      .doc("Sync")
      .update({ sync: new Date().getTime().toString() });

    handleClose();
  };
  return (
    <>
      <Button
        onClick={handleClickOpen}
        style={{ margin: "15px 30px 15px 0" }}
        variant="contained"
        color="primary"
        type="button"
      >
        Update Sync Value (
        <small style={{ textTransform: "none", fontSize: 12 }}>
          Last updated on{" "}
          {moment(new Date(syncValue)).format("ddd DD MMM hh:mm A")}
        </small>
        )
      </Button>
      <Dialog
        open={!!open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure, you want to update sync value, it'll update all user's
            local data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={updateSync} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Sync;
