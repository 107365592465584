import { firebaseReducer } from "react-redux-firebase";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";

import authReducer from "./authReducer";
import userReducer from "./userReducer";
import typeReducer from "./typeReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  userReducer: userReducer,
  typeReducer: typeReducer,
});

export default rootReducer;
