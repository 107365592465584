import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { connect } from "react-redux";
import { firestoreConnect, isLoaded } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import Loader from "../custom/loader";

import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

class Feedbacks extends Component {
  render() {
    const { feedbacks = [], auth, loading, } = this.props;


    if (!isLoaded()) {
      return <Loader />;
    }

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div
        style={{ width: "90%", margin: "auto", marginTop: 20 }}
      >
        <Typography style={{ marginLeft: 10 }} variant="h4">
        Feedbacks
        </Typography>
      
        <div style={{ clear: "both", height: 1, width: "100%" }}></div>
        {feedbacks ? (
          feedbacks.length ? (
            <TableContainer
              style={{ marginTop: 20, marginBottom: 20 }}
              component={Paper}
            >
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>No.</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Mobile No</TableCell>
                    <TableCell>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {feedbacks.map((feedback, index) => {
                    return (
                      <TableRow key={feedback.id}>
                        <TableCell>{index+1}.</TableCell>
                        <TableCell>{feedback.img && <img style={{width:100, height:100}} src={feedback.img} />}</TableCell>
                        <TableCell>{feedback.user}</TableCell>
                        <TableCell>{feedback.text}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            loading ? <></> : <Typography variant="h5">There are no user(s) to show</Typography>
          )
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("state => ",state)
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    feedbacks: state.firestore.ordered.feedback,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => ['feedback'])
)(Feedbacks);
