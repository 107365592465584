import * as actions from '../actions';

const initStateCreateUser = {
    loading: false,
    userDetails: null,
    error: null,
}

const initStateGetUser = {
    userLoading: false,
    users: null,
    userError: null
}

const initState = {
    ...initStateCreateUser,
    ...initStateGetUser
}

const userReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.GET_LIST_START:
            return {
                ...initStateGetUser,
                userLoading: true
            }
        case actions.GET_LIST_SUCCESS:
            return {
                ...initStateGetUser,
                userError: null,
                users: action.payload
            }
        case actions.GET_LIST_ERROR:
            return {
                ...initStateGetUser,
                userError: action.payload,
                users: null
            }

        default:
            return state
    }
}

export default userReducer