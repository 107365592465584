import React, { Component } from "react";
import { connect } from "react-redux";
import { isLoaded } from "react-redux-firebase";
import { Redirect } from "react-router-dom";
import { compose } from "redux";
import Loader from "../../custom/loader";

import UserTable from "./table";

class Users extends Component {
  state = {
    showMoreIndex: undefined,
  };

  render() {
    const { auth } = this.props;
    const { users = [] } = this.state;

    console.log("users => ", users);

    if (!isLoaded()) {
      return <Loader />;
    }

    if (!auth.uid) return <Redirect to="/signin" />;

    return (
      <div style={{ width: "90%", margin: "auto", marginTop: 20 }}>
        <UserTable />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("state => ", state?.firestore?.ordered?.Users);
  return {
    auth: state.firebase.auth,
    // profile: state.firebase.profile,
    // users: state.firestore.ordered.Users,
  };
};

export default compose(
  connect(mapStateToProps)
  // firestoreConnect(() => ["Users"])
)(Users);
